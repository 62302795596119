<template>
  <div class="typical-case">
    <div class="h">
      <!-- <h1 align="center" class="animate__animated animate__bounceInRight">
        PRODUCT SYSTEM
      </h1>
      <h2 align="center" class="animate__animated animate__bounceInRight">
        产品体系
      </h2> -->
    </div>

    <div v-if="$route.path === '/index/typicalCase'">
      <h2 align="center" class="subtitle">产品典型案例</h2>

      <div class="system-list">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in demoList" :key="item.id" @mouseover="switchItem(item)">
              <img :src="item.demoImg" />
              <div class="item-mask">
                <div>
                  <p class="h-p">{{ item.h }}</p>
                  <p class="t">{{ item.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul class="sys-list-item">
          <li v-for="item in caseList" :key="item.cId">
            <div class="sys-box-l">
              <img :src="item.cImg" alt="" />
            </div>
            <div class="sys-box-r">
              <h3>{{ item.cH }}</h3>
              <p class="p-one">{{ item.cText }}</p>
              <p class="p-two">
                <img src="../../assets/image/time.png" /><span>{{
                  item.date
                }}</span>
              </p>
              <button type="button" class="btn1" @click="show(item)">
                快速查看
              </button>
            </div>
          </li>
        </ul>
      </div>

      <!-- 服务流程 -->
      <h2 align="center" class="subtitle">服务流程</h2>
      <a-row class="process-flow" type="flex" justify="space-around">
        <a-col :sapn="4" class="pro-icon">
          <img src="../../assets/image/806.png" />
          <p>申请使用</p>
          <p>携手同行</p>
        </a-col>
        <a-col :sapn="1" class="pro-small">
          <img src="../../assets/image/767.png" />
        </a-col>
        <a-col :sapn="4" class="pro-icon">
          <img src="../../assets/image/807.png" />
          <p>专家客服</p>
          <p>答疑解惑</p>
        </a-col>
        <a-col :sapn="1" class="pro-small">
          <img src="../../assets/image/767.png" />
        </a-col>
        <a-col :sapn="4" class="pro-icon">
          <img src="../../assets/image/808.png" />
          <p>专家服务</p>
          <p>全程陪同</p>
        </a-col>
        <a-col :sapn="1" class="pro-small">
          <img src="../../assets/image/767.png" />
        </a-col>
        <a-col :sapn="4" class="pro-icon">
          <img src="../../assets/image/809.png" />
          <p>沟通需求</p>
          <p>优化迭代</p>
        </a-col>
        <a-col :sapn="1" class="pro-small">
          <img src="../../assets/image/767.png" />
        </a-col>
        <a-col :sapn="4" class="pro-icon">
          <img src="../../assets/image/810.png" />
          <p>贴心服务</p>
          <p>系统验收</p>
        </a-col>
      </a-row>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Swiper from "../../../node_modules/swiper/swiper-bundle.js";

export default {
  components: {},
  mounted() {
    console.log(this.$route);
    var mySwiper = new Swiper(".swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true,
      breakpoints: {
        320: {
          //当屏幕宽度大于等于320
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          //当屏幕宽度大于等于768
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1280: {
          //当屏幕宽度大于等于1280
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
      autoplay: {
        delay: 3000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      disableOnInteraction: false,
    });
  },
  data() {
    return {
      demoList: [
        {
          id: 1,
          h: "武汉CCR控制中心",
          text: "Semiconductor manufacturing",
          demoImg: require("../../assets/image/CCR-2.png"),
          caseEle: [
            {
              cId: 101,
              pId: 1,
              cH: "武汉CCR控制中心",
              cImg: require("../../assets/image/图片1.png"),
              cText:
                "武汉CCR控制中心，结合生产管理，操作控制，数据分析，视频监控等功能，打造集成中央智控中心； 在满足功能性需求基础之上，结合精酿文化，品牌传播，参观展示等，充分弘扬品牌价值和艺术文化； 工厂实行扁平化管理，实现操作、设备维修、质量管理一体化集成；实现工厂酿造，包装，动力和质检一体化管理，武汉CCR控制中心，结合生产管理，操作控制，数据分析，视频监控等功能，打造集成中央智控中心； 在满足功能性需求基础之上，结合精酿文化，品牌传播，参观展示等，充分弘扬品牌价值和艺术文化； 工厂实行扁平化管理，实现操作、设备。",
              date: "2020.11.01",
              name: "demoDetails",
            },
            {
              cId: 102,
              pId: 1,
              cH: "酿酒行业控制系统",
              cImg: require("../../assets/image/图片2.png"),
              cText: "酿酒行业控制系统解决方案",
              date: "2020.11.01",
              name: "brewInfo",
            },
            {
              cId: 103,
              pId: 1,
              cH: "粮油行业控制系统",
              cImg: require("../../assets/image/图片2.png"),
              cText:
                "粮油行业自动化控制系统解决方案，赋能粮油行业提高生产效率和质量一致性。",
              date: "2020.11.01",
              name: "egrainInfo",
            },
            {
              cId: 104,
              pId: 1,
              cH: "移动工业APP",
              cImg: require("../../assets/image/图片2.png"),
              cText:
                "移动工业APP，生产计划、操作、质检、维护保养以及班组管理和设备管理等一站式个人系统门户。",
              date: "2020.11.01",
              name: "appInfo",
            },
          ],
        },
        {
          id: 2,
          h: "武汉CCR控制中心",
          text: "automotive industry",
          demoImg: require("../../assets/image/CCR-3.png"),
          caseEle: [
            {
              cId: 101,
              pId: 1,
              cH: "武汉CCR控制中心",
              cImg: require("../../assets/image/图片1.png"),
              cText:
                "武汉CCR控制中心，结合生产管理，操作控制，数据分析，视频监控等功能，打造集成中央智控中心； 在满足功能性需求基础之上，结合精酿文化，品牌传播，参观展示等，充分弘扬品牌价值和艺术文化； 工厂实行扁平化管理，实现操作、设备维修、质量管理一体化集成；实现工厂酿造，包装，动力和质检一体化管理，武汉CCR控制中心，结合生产管理，操作控制，数据分析，视频监控等功能，打造集成中央智控中心； 在满足功能性需求基础之上，结合精酿文化，品牌传播，参观展示等，充分弘扬品牌价值和艺术文化； 工厂实行扁平化管理，实现操作、设备。",
              date: "2020.11.01",
              name: "demoDetails",
            },
            {
              cId: 102,
              pId: 1,
              cH: "酿酒行业控制系统",
              cImg: require("../../assets/image/图片2.png"),
              cText: "酿酒行业控制系统解决方案",
              date: "2020.11.01",
              name: "brewInfo",
            },
            {
              cId: 103,
              pId: 1,
              cH: "粮油行业控制系统",
              cImg: require("../../assets/image/图片2.png"),
              cText:
                "粮油行业自动化控制系统解决方案，赋能粮油行业提高生产效率和质量一致性。",
              date: "2020.11.01",
              name: "egrainInfo",
            },
            {
              cId: 104,
              pId: 1,
              cH: "移动工业APP",
              cImg: require("../../assets/image/图片2.png"),
              cText:
                "移动工业APP，生产计划、操作、质检、维护保养以及班组管理和设备管理等一站式个人系统门户。",
              date: "2020.11.01",
              name: "appInfo",
            },
          ],
        },
        {
          id: 3,
          h: "武汉CCR控制中心",
          text: "Hardware manufacturing",
          demoImg: require("../../assets/image/CCR-4.png"),
          caseEle: [
            {
              cId: 101,
              pId: 1,
              cH: "武汉CCR控制中心",
              cImg: require("../../assets/image/图片1.png"),
              cText:
                "武汉CCR控制中心，结合生产管理，操作控制，数据分析，视频监控等功能，打造集成中央智控中心； 在满足功能性需求基础之上，结合精酿文化，品牌传播，参观展示等，充分弘扬品牌价值和艺术文化； 工厂实行扁平化管理，实现操作、设备维修、质量管理一体化集成；实现工厂酿造，包装，动力和质检一体化管理，武汉CCR控制中心，结合生产管理，操作控制，数据分析，视频监控等功能，打造集成中央智控中心； 在满足功能性需求基础之上，结合精酿文化，品牌传播，参观展示等，充分弘扬品牌价值和艺术文化； 工厂实行扁平化管理，实现操作、设备。",
              date: "2020.11.01",
              name: "demoDetails",
            },
            {
              cId: 102,
              pId: 1,
              cH: "酿酒行业控制系统",
              cImg: require("../../assets/image/图片2.png"),
              cText: "酿酒行业控制系统解决方案",
              date: "2020.11.01",
              name: "brewInfo",
            },
            {
              cId: 103,
              pId: 1,
              cH: "粮油行业控制系统",
              cImg: require("../../assets/image/图片2.png"),
              cText:
                "粮油行业自动化控制系统解决方案，赋能粮油行业提高生产效率和质量一致性。",
              date: "2020.11.01",
              name: "egrainInfo",
            },
            {
              cId: 104,
              pId: 1,
              cH: "移动工业APP",
              cImg: require("../../assets/image/图片2.png"),
              cText:
                "移动工业APP，生产计划、操作、质检、维护保养以及班组管理和设备管理等一站式个人系统门户。",
              date: "2020.11.01",
              name: "appInfo",
            },
          ],
        },
        {
          id: 4,
          h: "武汉CCR控制中心",
          text: "Logistics communication",
          demoImg: require("../../assets/image/CCR-5.png"),
          caseEle: [
            {
              cId: 101,
              pId: 1,
              cH: "武汉CCR控制中心",
              cImg: require("../../assets/image/图片1.png"),
              cText:
                "武汉CCR控制中心，结合生产管理，操作控制，数据分析，视频监控等功能，打造集成中央智控中心； 在满足功能性需求基础之上，结合精酿文化，品牌传播，参观展示等，充分弘扬品牌价值和艺术文化； 工厂实行扁平化管理，实现操作、设备维修、质量管理一体化集成；实现工厂酿造，包装，动力和质检一体化管理，武汉CCR控制中心，结合生产管理，操作控制，数据分析，视频监控等功能，打造集成中央智控中心； 在满足功能性需求基础之上，结合精酿文化，品牌传播，参观展示等，充分弘扬品牌价值和艺术文化； 工厂实行扁平化管理，实现操作、设备。",
              date: "2020.11.01",
              name: "demoDetails",
            },
            {
              cId: 102,
              pId: 1,
              cH: "酿酒行业控制系统",
              cImg: require("../../assets/image/图片2.png"),
              cText: "酿酒行业控制系统解决方案",
              date: "2020.11.01",
              name: "brewInfo",
            },
            {
              cId: 103,
              pId: 1,
              cH: "粮油行业控制系统",
              cImg: require("../../assets/image/图片2.png"),
              cText:
                "粮油行业自动化控制系统解决方案，赋能粮油行业提高生产效率和质量一致性。",
              date: "2020.11.01",
              name: "egrainInfo",
            },
            {
              cId: 104,
              pId: 1,
              cH: "移动工业APP",
              cImg: require("../../assets/image/图片2.png"),
              cText:
                "移动工业APP，生产计划、操作、质检、维护保养以及班组管理和设备管理等一站式个人系统门户。",
              date: "2020.11.01",
              name: "appInfo",
            },
          ],
        },
      ],
      bgDemoImg: "",

      // 这是每一个分类的元素列表
      caseList: [],
    };
  },

  methods: {
    switchItem(item) {
      this.caseList = item.caseEle;
    },

    show(item) {
      console.log(item);
      this.$router.push({ name: item.name });
    },
  },
};
</script>
<style lang="less">
.typical-case {
  position: relative;

  .h {
    width: 100%;
    position: absolute;
    top: -320px;

    h1 {
      color: #ffffff;
      font-size: 72px;
      font-family: Quantum;
      font-weight: 400;
    }

    h2 {
      color: #ffffff;
      font-size: 44px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
  }

  .subtitle {
    margin-top: 60px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
  }

  .system-list {
    .row-list-top {
      width: 1440px;
      margin: 0 auto;
      // top: -40px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 10px 20px #cccccc;

      .top-item {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .p1 {
          margin-top: 24px;
          margin-bottom: 0px;
          font-size: 22px;
          font-family: Source Han Sans CN;
          font-weight: 500;
        }

        .p2 {
          margin-top: 8px;
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }

        &:hover {
          color: #ffffff;
          // background: url("../assets/image/793.png");
          transition: all 0.5s ease-out;
        }
      }
    }

    .sys-list-item {
      width: 1240px;
      margin: 0 auto;

      // background-color: aqua;
      li {
        width: 100%;
        margin-top: 60px;
        display: flex;

        .sys-box-l {
          width: 40%;
          padding: 15px;
          background: url("../../assets/image/路径 29041.png") no-repeat;
          background-position: 0 100%;

          img {
            width: 100%;
            height: 100%;
          }

          h3 {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
          }

          .p1 {
            font-size: 12px;
            color: cadetblue;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }
        }

        .sys-box-r {
          padding: 30px;
          width: 60%;

          h3 {
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
          }

          .p-one {
            margin-top: 16px;
            font-size: 5px;
            // font-family: Source Han Sans CN;
            // font-weight: 400;
          }

          .p-two {
            margin-top: 16px;

            img {
              margin-right: 20px;
            }
          }

          .btn1 {
            margin-top: 20px;
            width: 200px;
            padding: 10px;
            background: rgba(255, 255, 255, 0.39);
            border-radius: 10px;
            border: 1px solid #e85500;
            color: #e85500;
            bottom: 15px;
            // transition: all 0.2s ease;
            cursor: pointer;

            &:active {
              background-color: #e85500;
              color: #ffffff;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .process-flow {
    width: 1440px;
    margin: 0 auto;
    padding-bottom: 40px;

    // background-color: cadetblue;
    .pro-icon {
      text-align: center;

      p {
        margin: 0;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }

      img {
        width: 206px;
        height: 198px;
      }
    }

    .pro-small {
      display: flex;
      align-items: center;

      img {
        line-height: 100%;
        width: 58px;
        height: 14px;
      }
    }
  }

  .swiper {
    width: 1440px;
    margin: 0 auto;
    border-radius: 20px;

    .swiper-wrapper {
      .swiper-slide {
        border-radius: 20px;
        position: relative;
        overflow: hidden;

        .item-mask {
          cursor: pointer;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.6);
          opacity: 0;
          color: #ffffff;
          text-align: center;
          transition: 0.5s opacity ease;

          &:hover {
            opacity: 1;
          }

          display: flex;
          align-items: flex-end;
          justify-content: center;

          div {
            // position: absolute;
            // top: 180px;
            // z-index: 999;
            // width: 100%;
            // height: 100%;
            background: url("../../assets/image/804.png") no-repeat;
            background-position: 100% 100%;
            background-size: 100% 100%;
            // border-radius: 20px;
            padding: 20px;
            margin: 0 auto;

            .h-p {
              margin: 0;
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 500;
            }

            .t {
              margin-top: 25px;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media all and (max-width: 700px) {
  .typical-case {
    position: relative;

    .h {
      width: 100%;
      position: absolute;
      top: -370px;

      h1 {
        color: #ffffff;
        font-size: 100px;
        font-family: Quantum;
        font-weight: 400;
      }

      h2 {
        color: #ffffff;
        font-size: 54px;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }

    .subtitle {
      margin-top: 60px;
      font-size: 80px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    .system-list {
      .row-list-top {
        width: 1440px;
        margin: 0 auto;
        // top: -40px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 10px 20px #cccccc;

        .top-item {
          padding: 50px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .p1 {
            margin-top: 24px;
            margin-bottom: 0px;
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 500;
          }

          .p2 {
            margin-top: 8px;
            font-size: 11px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }

          &:hover {
            color: #ffffff;
            // background: url("../assets/image/793.png");
            transition: all 0.5s ease-out;
          }
        }
      }

      .sys-list-item {
        width: 1240px;
        margin: 0 auto;
        // background-color: aqua;
        li {
          width: 100%;
          margin-top: 60px;
          display: flex;
          flex-wrap: wrap;
          .sys-box-l {
            width: 100%;
            padding: 15px;
            background: url("../../assets/image/路径 29041.png") no-repeat;
            background-position: 0 100%;

            img {
              width: 100%;
              height: 100%;
            }

            h3 {
              font-size: 48px;
              font-family: Source Han Sans CN;
              font-weight: bold;
            }

            .p1 {
              font-size: 24px;
              color: cadetblue;
              font-family: Source Han Sans CN;
              font-weight: 400;
            }
          }

          .sys-box-r {
            padding: 30px;
            width: 100%;

            h3 {
              font-size: 58px;
              font-family: Source Han Sans CN;
              font-weight: bold;
            }

            .p-one {
              text-indent: 1rem;
              margin-top: 16px;
              font-size: 48px;
              // font-family: Source Han Sans CN;
              // font-weight: 400;
            }

            .p-two {
              margin-top: 16px;

              img {
                width: 100px;
                height: 100px;
                margin-right: 20px;
              }
              span {
                font-size: 38px;
              }
            }

            .btn1 {
              margin-top: 20px;
              width: 500px;
              height: 100px;
              padding: 10px;
              background: rgba(255, 255, 255, 0.39);
              border-radius: 20px;
              border: 1px solid #e85500;
              color: #e85500;
              bottom: 15px;
              // transition: all 0.2s ease;
              cursor: pointer;
              font-size: 48px;
              &:active {
                background-color: #e85500;
                color: #ffffff;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .process-flow {
      width: 1440px;
      margin: 0 auto;
      padding-bottom: 40px;

      // background-color: cadetblue;
      .pro-icon {
        text-align: center;

        p {
          margin: 0;
          font-size: 48px;
          font-family: Source Han Sans CN;
          font-weight: 800;
        }

        img {
          width: 206px;
          height: 198px;
        }
      }

      .pro-small {
        display: flex;
        align-items: center;

        img {
          line-height: 100%;
          width: 58px;
          height: 14px;
        }
      }
    }

    .swiper {
      width: 1440px;
      margin: 0 auto;
      border-radius: 20px;

      .swiper-wrapper {
        .swiper-slide {
          border-radius: 20px;
          position: relative;
          overflow: hidden;

          .item-mask {
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.6);
            opacity: 0;
            color: #ffffff;
            text-align: center;
            transition: 0.5s opacity ease;

            &:hover {
              opacity: 1;
            }

            display: flex;
            align-items: flex-end;
            justify-content: center;

            div {
              // position: absolute;
              // top: 180px;
              // z-index: 999;
              // width: 100%;
              // height: 100%;
              background: url("../../assets/image/804.png") no-repeat;
              background-position: 100% 100%;
              background-size: 100% 100%;
              // border-radius: 20px;
              padding: 20px;
              margin: 0 auto;

              .h-p {
                margin: 0;
                font-size: 58px;
                font-family: Source Han Sans CN;
                font-weight: 500;
              }

              .t {
                margin-top: 25px;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 400;
              }
            }
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}</style>
